import React, { Component } from 'react';
  import '../components/integrations.css';
  import { Row, Col, Form } from 'react-bootstrap';
  import logo1 from '../images/azurelogo.png';
  import aws from '../images/aws.png';
  import mfsm from '../images/mfsm.svg';
  import o365 from '../images/o365.png';
  import topdesk from '../images/topdesk.png';
  import winad from '../images/winad.png';
  import mssql from '../images/mssql.png';
  import winserver from '../images/winserver.png';
  import jira from '../images/jira.png';
  import freshdesk from '../images/freshdesk.svg';
  import vmware from '../images/vmware.png';
  import win10 from '../images/win10.png';
  import digitalocean from '../images/digitalocean.svg';
  import mscert from '../images/mscert.svg';
  import freshservice from '../images/freservice.png';
  import bmc from '../images/bmc.png';
  import powershell from '../images/powershell.png';
  import oracledb from '../images/oracledb.png';
  import servicenow from '../images/servicenow.png';
  import zendesk from '../images/zendesk.png';
  import hyperv from '../images/hyperv.png';
  import citrix from '../images/citrix.png';
  import exchange from '../images/exchange.png';
  import {Helmet} from "react-helmet";
  import HU from '../components/hu-HU_lang.js';
import EN from '../components/en-EN_lang.js';
import Layout from "../components/layout";
class Integrations extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[],
      scrolling: true
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== this.state.scrolling) {
          this.setState({ scrolling })
      }
    });
  }
  
render(){
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
    return(
      <Layout>
        <div className={this.state.scrolling ? "otherPageDiv" : "otherPageDivScrolling"}>
            <div className="titleDiv">
              <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsHeader : EN.integrationsHeader}</h2>
              <Helmet>
                <title>Integrációk - Fedezze fel az IT Automatizálási integrációink</title>
                <meta name="description" content="Jelenleg elérhető integrációinkon a feladatokra és folyamatokra koncentrálunk a felhasználói igény önkiszolgálásától kezdve a tényleges feladat megoldásáig és annak adminisztrációjáig a megfelelő módszertan szerint, csupán egyetlen gombnyomásra van szükség." />
                <meta property="og:title" content="Integrációk - Fedezze fel az IT Automatizálási integrációink" />
                <meta property="og:type" content="website" />
                <meta property="og:description " content="Jelenleg elérhető integrációinkon a feladatokra és folyamatokra koncentrálunk a felhasználói igény önkiszolgálásától kezdve a tényleges feladat megoldásáig és annak adminisztrációjáig a megfelelő módszertan szerint, csupán egyetlen gombnyomásra van szükség." />
                <meta property="og:url" content="https://automeasify.com/integraciok" />
                <meta property="og:image" content="https://blingdy.app/uploads/banner828_7146d1f5a9.png" />
              </Helmet>
            </div>
            <div className="contentDiv">
              <div className="contentDivFyler1">
                <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsContent : EN.integrationsContent}</h2>
                <Row className="integrRow">
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={aws} />
                      <h4>Amazon Web Services</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={o365} />
                      <h4>MS Office 365</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={mfsm} />
                      <h4>Micro Focus Service Manager</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={logo1} />
                      <h4>Microsoft Azure</h4>
                    </div>
                  </Col>
                </Row>
                <Row className="integrRow">
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={topdesk} />
                      <h4>Topdesk ITSM</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={winad} />
                      <h4>MS Active Directory</h4>
                    </div>
                  </Col>
                  
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={mssql} />
                      <h4>Microsoft SQL</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={winserver} />
                      <h4>Windows Servers</h4>
                    </div>
                  </Col>
                </Row>
                <Row className="integrRow">
                <Col xs={6} xl={3} md={3}>
                <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={vmware} />
                      <h4>VMWare</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={jira} />
                      <h4>JIRA Service Desk</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={freshdesk} />
                      <h4>Freshdesk</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={win10} />
                      <h4>Microsoft Windows 10</h4>
                    </div>
                  </Col>
                </Row>
                <Row className="integrRow">
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={digitalocean} />
                      <h4>Digital Ocean</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={mscert} />
                      <h4>Microsoft Certificate Authority</h4>
                    </div>
                  </Col>
                  
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={bmc} />
                      <h4>BMC Remedy</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={freshservice} />
                      <h4>Freshservice</h4>
                    </div>
                  </Col>
                </Row>
                <Row className="integrRow">
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={servicenow} />
                      <h4>Service NOW</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={powershell} />
                      <h4>Microsoft Powershell</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={oracledb} />
                      <h4>Oracle Database</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={zendesk} />
                      <h4>Zendesk</h4>
                    </div>
                  </Col>
                </Row>
                <Row className="integrRow">
                <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={hyperv} />
                      <h4>Microsoft Hyper-V</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormalsActive">
                      <img altr="integrationAzureLogo" src={exchange} />
                      <h4>Microsoft Exchange</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  <div className="integrNormals">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.integrationsBoxHead : EN.integrationsBoxHead}</h6>
                      <img altr="integrationAzureLogo" src={citrix} />
                      <h4>Citrix</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                  
                  </Col>
                </Row>
              </div>
            </div>
        </div>
        </Layout>
    );
  }
}
export default Integrations